@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type='number'] {
		-moz-appearance: textfield;
	}

	select {
		-moz-appearance: none; /* Firefox */
		-webkit-appearance: none; /* Safari and Chrome */
		appearance: none;
		background-image: url('./assets/chevron-down.svg');
		background-position: right 0.5rem center;
		background-repeat: no-repeat;
		background-size: 1rem 1rem;
		padding-right: 2.5rem;
		-webkit-print-color-adjust: exact;
		color-adjust: exact;
	}
}

@layer utilities {
	.parallel-gradient {
		background-image: -webkit-gradient(
			linear,
			left top,
			right top,
			from(#c0fb7b),
			to(#ffe700)
		);
		background-image: linear-gradient(90deg, #c0fb7b, #ffe700);
	}

	.loader {
		border-top-color: #3498db;
		-webkit-animation: spinner 1.5s linear infinite;
		animation: spinner 1.5s linear infinite;
	}

	@-webkit-keyframes spinner {
		0% {
			-webkit-transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
		}
	}

	@keyframes spinner {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}
